import { NgModule } from '@angular/core';
import { TableComponent } from './components/table/table.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { AdvertisementsComponent } from './components/advertisements/advertisements.component';
import { CommonModule } from '@angular/common';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { OnlyLettersDirective } from './directives/only-letters.directive';
import { DomSeguroPipe } from '../pipes/dom-seguro.pipe';
import { HeaderPublicComponent } from './components/header-public/header-public.component';
import { FooterLogoutComponent } from './components/footer-logout/footer-logout.component';

@NgModule({
  declarations: [
      HeaderComponent,
      FooterComponent,
      FooterLogoutComponent,
      TableComponent,
      AdvertisementsComponent,
      OnlyNumbersDirective,
      OnlyLettersDirective,
      DomSeguroPipe,
      HeaderPublicComponent,
  ],
    exports: [
        HeaderComponent,
        FooterComponent,
        FooterLogoutComponent,
        TableComponent,
        AdvertisementsComponent,
        OnlyNumbersDirective,
        OnlyLettersDirective,
        HeaderPublicComponent,
    ],
  imports: [
    RouterModule,
      CommonModule
  ]
})
export class SharedModule { }
