<div class="cover-container d-flex w-100 h-100 p-0 mx-auto flex-column contact__no-login">

  <header class="masthead mb-auto">
    <div class="inner">
    </div>
  </header>

  <section role="main" class="inner cover py-0 px-3">

    <!-- Begin Begin Page Content -->
    <div class="container py-3">
      <!-- 404 Error Text -->
      <div class="text-center mt-3">
        <img class="mb-4" src="../../assets/images/navegador.svg" alt="" width="300" height="auto">
        <!-- <div class="error mx-auto" data-text="500">500</div> -->
        <h4 class="text-gray mb-3">Navegador no Compatible</h4>
        <p class="text-gray-300 mb-5">Estás usando un navegador antiguo e inseguro que no es compatible, te invitamos a
          actulizar tu navegador:</p>
        <div class="browsers">
          <a href="https://www.google.com/intl/es_ALL/chrome/" target="_blank">
            <img src="../../assets/images/chrome-logo.png">
            <span>Chrome</span>
          </a>
          <a href="https://support.apple.com/kb/DL1569?viewlocale=es_ES&locale=es_ES" target="_blank">
            <img src="../../assets/images/safari-logo.png">
            <span>Safari</span>
          </a>
          <a href="https://www.mozilla.org/es-ES/firefox/new/" target="_blank">
            <img src="../../assets/images/firefox-logo.png">
            <span>Firefox</span>
          </a>
          <a href="https://www.microsoft.com/es-co/windows/microsoft-edge" target="_blank">
            <img src="../../assets/images/edge-logo.png">
            <span>Edge</span>
          </a>
        </div>
      </div>
    </div>
    <!-- End Begin Page Content -->

  </section>

  <!-- Footer -->
  <footer class="footer__no-login mt-auto py-3 bg-dark-gray text-white text-center">
    <div class="container ¿">
      <small>
        &copy; 2019 - Portal Proveedores Siesa |
        <a [routerLink]="['','login']">Iniciar Sesión</a> |
        <a [routerLink]="['','contact']">Contáctenos</a> |
        <a [routerLink]="['','supplier-registration']">Registro de Proveedores</a> |
        <a [routerLink]="['','privacy']">Política de Tratamiento de Datos</a> |
        <a [routerLink]="['','terms']">Términos y Condiciones</a>
      </small>
    </div>
  </footer>
  <!-- End Footer -->

</div>