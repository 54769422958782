import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '@app/common/services/env.service';
import { constants } from '@app/common/constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  private url: string;
  private contenidoIntro: any = {};
  private contenidoTips: any = {};

  constructor(private http: HttpClient,
    private env: EnvService) {
  }

  recoveryPassword(useremail: string, company: string) {
    const url = this.env.apiGatewayFront + constants.config.recoverPassword;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    const body: FormData = new FormData();
    body.append('data', 'AGP');
    body.append('email', useremail);
    body.append('company', company);

    return this.http.post(url, body, { headers });
  }

  contentIntro(ubucation: string) {
    const urlCms = this.env.apiGatewayFront + constants.config.getContentCms + `=${ubucation}`;
    this.contenidoIntro = this.http.get(urlCms);
    return this.contenidoIntro;
  }

  contentTips(ubucation: string) {
    const urlCms = this.env.apiGatewayFront + constants.config.getContentCms + `=${ubucation}`;
    this.contenidoTips = this.http.get(urlCms);
    return this.contenidoTips;
  }

}
