<app-header-public></app-header-public>
<!-- Main Content -->
<main role="main" class="content__main">
  <!-- Begin Begin Page Content -->


    <div class="container">



    <!-- Title -->
    <div class="d-sm-flex align-items-center justify-content-between mb-2">
      <h1 class="font-weight-bold h3 mb-0 text-gray">Política de Tratamiento de Datos</h1>
    </div>
    <!-- End Title -->

    <!-- Breadcrumb -->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['','login']"><i class="far fa-user"></i></a></li>
        <li class="breadcrumb-item active" aria-current="page">Política de Tratamiento de Datos</li>
      </ol>
    </nav>
    <!-- End Breadcrumb -->

    <!-- Content -->
    <div class="row">

      <!-- Header -->
      <div class="col-md-12">
        <div *ngIf="cmsData" class="banner">
          <app-advertisements *ngIf="cmsData.espacios[1]"  [typeContent]="'espacios'" [positions]="1" [contentData]="cmsData"></app-advertisements>
        </div>
      </div>
      <!-- End Header -->

      <div class="col-md-8 mb-4 order-md-1">

        <div *ngIf="cmsData" >
          <app-advertisements *ngIf="cmsData.espacios[2]"  [typeContent]="'espacios'" [positions]="2" [contentData]="cmsData"></app-advertisements>
        </div>
      </div>

      <!-- Sidebar -->
      <div class="col-md-4 order-md-2 mb-4">
        <div *ngIf="cmsData" class="banner">
          <app-advertisements *ngIf="cmsData.espacios[3]"  [typeContent]="'espacios'" [positions]="3" [contentData]="cmsData"></app-advertisements>
        </div>
      </div>
      <!-- End Sidebar -->

    </div>
    <!-- End Content -->

  </div>
  <!-- End Begin Page Content -->
</main>
<!-- Main Content -->

<!-- Footer -->
<!-- <footer class="mastfoot mt-auto py-3 text-center">
  <div class="container">
    <small>
      &copy; 2019 - Portal Proveedores Siesa |
      <a class="mastfoot--link" [routerLink]="['','login']">Iniciar Sesión</a> |
      <a class="mastfoot--link" *ngIf="contentB2s.contact > 0" [routerLink]="['','contact-us']">Contáctenos  |</a>
      <a class="mastfoot--link" *ngIf="contentB2s.provider > 0" [routerLink]="['','search-company']">Registro de Proveedores |</a>
      <a class="mastfoot--link" [routerLink]="['','privacy']">Política de Tratamiento de Datos</a> |
      <a class="mastfoot--link" [routerLink]="['','terms']">Términos y Condiciones</a>
    </small>
  </div>
</footer> -->

<app-footer-logout></app-footer-logout>
<!-- End Footer -->
