import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './router/app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './layout/login/login.component';
import { ContactComponent } from './layout/contact/contact.component';
import { SupplierRegistrationComponent } from './layout/supplier-registration/supplier-registration.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TermsComponent } from './layout/terms/terms.component';
import { PrivacyComponent } from './layout/privacy/privacy.component';
import { BrowserNotSupportedComponent } from './browser-not-supported/browser-not-supported.component';
import { OfflineExpectationComponent } from './layout/offline-expectation/offline-expectation.component';
import { ModulesComponent } from './core/components/modules.component';
import { SharedModule } from './shared/shared.module';
import { ThirdPartyModulesModule } from './third-party.module';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './gards/auth.gard';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InternalErrorComponent } from './core/components/internal-error/internal-error.component';

import { PasswordComponent } from './password/password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { ContactUsComponent } from './layout/contact-us/contact-us.component';
import { NgxPaginationModule } from 'ngx-pagination';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxUiLoaderModule, NgxUiLoaderHttpModule, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { OriginInterceptor } from './shared/interceptors/origin.interceptor';
import { CookieInterceptor } from './shared/interceptors/cookie.interceptor';
import { KeepHtmlPipe } from './pipes/keep-html.pipe';
import { constants } from './common/constants/app.constants';
import { SearchCompanyComponent } from './layout/search-company/search-company.component';
import { SupplierRegistrationCompanyComponent } from './layout/supplier-registration-company/supplier-registration-company.component';

import { BnNgIdleService } from 'bn-ng-idle'; // import bn-ng-idle service
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';

const dbConfig: DBConfig = {
  name: 'account_DB',
  version: 2,
  objectStoresMeta: [{
    store: 'account_statements',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'compania', keypath: 'compania', options: { unique: false } },
      { name: 'condicion_pago', keypath: 'condicion_pago', options: { unique: false } },
      { name: 'dias_vencidos', keypath: 'dias_vencidos', options: { unique: false } },
      { name: 'estado_programacion_pago', keypath: 'estado_programacion_pago', options: { unique: false } },
      { name: 'factura_cliente', keypath: 'factura_cliente', options: { unique: false } },
      { name: 'fecha_estimada_pago', keypath: 'fecha_estimada_pago', options: { unique: false } },
      { name: 'fecha_factura', keypath: 'fecha_factura', options: { unique: false } },
      { name: 'fecha_proveedor', keypath: 'fecha_proveedor', options: { unique: false } },
      { name: 'fecha_vencimiento', keypath: 'fecha_vencimiento', options: { unique: false } },
      { name: 'id', keypath: 'id', options: { unique: true } },
      { name: 'saldo_corriente', keypath: 'saldo_corriente', options: { unique: false } },
      { name: 'saldo_total', keypath: 'saldo_total', options: { unique: false } },
      { name: 'saldo_vencido', keypath: 'saldo_vencido', options: { unique: false } },
      { name: 'valor_facturado', keypath: 'valor_facturado', options: { unique: false } }
    ]
  },
  {
    store: 'detail_purchase_order',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'nro_orden', keypath: 'nro_orden', options: { unique: false } },
      { name: 'idPurchase', keypath: 'idPurchase', options: { unique: false } },
    ]
  },
  {
    store: 'paymentStatements',
    storeConfig: { keyPath: 'idPayment', autoIncrement: true },
    storeSchema: [
      { name: 'nro_orden', keypath: 'nro_orden', options: { unique: false } },
      { name: 'idPurchase', keypath: 'idPurchase', options: { unique: false } },
    ]
  },
  {
    store: 'purchase_order',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'nro_orden', keypath: 'nro_orden', options: { unique: false } },
      { name: 'idPurchase', keypath: 'idPurchase', options: { unique: false } },
    ]
  },
  {
    store: 'purchase_tickets',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'nro_entrada', keypath: 'nro_entrada', options: { unique: false } },
      { name: 'fecha', keypath: 'fecha', options: { unique: false } },
      { name: 'valor_bruto', keypath: 'valor_bruto', options: { unique: false } },
      { name: 'valor_impuesto', keypath: 'valor_impuesto', options: { unique: false } },
      { name: 'valor_neto', keypath: 'valor_neto', options: { unique: false } },
      { name: 'oc_relacionada', keypath: 'oc_relacionada', options: { unique: false } },
    ]
  },
  {
    store: 'detail_purchase_tickets',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'item', keypath: 'item', options: { unique: false } },
      { name: 'extension', keypath: 'extension', options: { unique: false } },
      { name: 'um', keypath: 'um', options: { unique: false } },
      { name: 'cantidad', keypath: 'cantidad', options: { unique: false } },
      { name: 'precio', keypath: 'precio', options: { unique: false } },
      { name: 'valor_bruto', keypath: 'valor_bruto', options: { unique: false } },
      { name: 'valor_descuento', keypath: 'valor_descuento', options: { unique: false } },
      { name: 'iva', keypath: 'iva', options: { unique: false } },
      { name: 'canti_adicional', keypath: 'canti_adicional', options: { unique: false } }
    ]
  },
  {
    store: 'notes-debito',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'nro_nota', keypath: 'nro_entrada', options: { unique: false } },
      { name: 'fecha', keypath: 'fecha', options: { unique: false } },
      { name: 'valor_bruto', keypath: 'valor_bruto', options: { unique: false } },
      { name: 'valor_impuesto', keypath: 'valor_impuesto', options: { unique: false } },
      { name: 'valor_descuento', keypath: 'valor_descuento', options: { unique: false } },
      { name: 'valor_nota', keypath: 'valor_nota', options: { unique: false } },
    ]
  },
  {
    store: 'detail-notes-debito',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'doc_cruce', keypath: 'doc_cruce', options: { unique: false } },
      { name: 'saldo', keypath: 'saldo', options: { unique: false } },
      { name: 'valor', keypath: 'valor', options: { unique: false } },
      { name: 'saldo_nuevo', keypath: 'saldo_nuevo', options: { unique: false } },
    ]
  },
  {
    store: 'supplier_contracts',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'nro_orden', keypath: 'nro_orden', options: { unique: false } },
      { name: 'idPurchase', keypath: 'idPurchase', options: { unique: false } },
    ]
  },
  ]
};

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#00ACC1',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 60,
  bgsType: 'ball-spin-clockwise',
  blur: 8,
  fgsColor: 'rgba(255,255,255,0.77)',
  fgsPosition: 'center-center',
  fgsSize: 70,
  fgsType: 'ball-spin-clockwise',
  gap: 48,
  logoPosition: 'center-center',
  logoSize: 120,
  logoUrl: '',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(40, 40, 40, 0.8)',
  pbColor: '#0a4b96',
  pbDirection: 'ltr',
  pbThickness: 4,
  hasProgressBar: true,
  text: 'Procesando la solicitud...',
  textColor: '#FFFFFF',
  textPosition: 'bottom-center',
  threshold: 500
};

const url = constants.config.apiGatewayFront;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ContactComponent,
    SupplierRegistrationComponent,
    TermsComponent,
    PrivacyComponent,
    BrowserNotSupportedComponent,
    OfflineExpectationComponent,
    ModulesComponent,
    NotFoundComponent,
    InternalErrorComponent,
    NotFoundComponent,
    PasswordComponent,
    ContactUsComponent,
    KeepHtmlPipe,
    SearchCompanyComponent,
    SupplierRegistrationCompanyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    ThirdPartyModulesModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ScrollingModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
      exclude: [
        `${url}${constants.config.adverstismentApi}`,
        `${url}${constants.config.pageOffline}`,
        `${url}${constants.config.getContentCmsInfo}`,
        `${url}${constants.config.adverstismentCmsSpaces}`,
      ]
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ToastrModule.forRoot({
      timeOut: 10000,
      extendedTimeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true,
    }),
    CommonModule,
    BrowserAnimationsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxPaginationModule,
    NgxIndexedDBModule.forRoot(dbConfig),
  ],
  exports: [
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxPaginationModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CookieInterceptor, multi: true },
    AuthGuard,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: constants.config.siteKeyCaptcha,
      } as RecaptchaSettings,
    },
    BnNgIdleService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
