import { Component, OnInit, VERSION } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { AffairsContactService } from '../../common/services/affairs-contact.service';
import { RelatedToService } from '../../common/services/related-to.service';
import { ContactThroughService } from '../../common/services/contact-through.service';
import { CompanieInfoService } from '../../common/services/companie-info.service';

import { ContactService } from './contact.service';
import { AdverstismentService } from '../../common/services/adverstisment.service';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../common/services/login.service';
import { HeaderService } from '../../common/services/header.service';


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  public affairs: any;
  public relatedto: any;
  public contactThroughs: any;
  public companieInfo: any;
  public contact: any;
  public page = "B2S-PAG-7";
  public cmsData: any;
  public registerForm: FormGroup;
  public isAuthenticate = false;
  public disabledButton = false;
  public userData: any;
  public detalles_contacto: any;
  public selectedCompany;
  b2sContent: any;
  nombre: string;
  apellido: string;
  correo: string;
  telefono: string;
  telefono_2: string;
  numero_tele: string;
  indicativo: string;
  dir: string;
  horario: string;
  ext: string;

  public contactUsModel = {
    name: '',
    surname: '',
    email: '',
    city: '',
    phone: '',
    indicative: ''
  };

  constructor(
    private affairService: AffairsContactService,
    private relatedtoService: RelatedToService,
    private contactThroughService: ContactThroughService,
    private companieInfoService: CompanieInfoService,
    private contactService: ContactService,
    private adService: AdverstismentService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private _authService: LoginService,
    private _hedaerService: HeaderService
  ) {
    this.isAuthenticate = JSON.parse(localStorage.getItem('authenticate'));

    if (localStorage.getItem('userData') !== null) {
      this.userData = JSON.parse(localStorage.getItem('userData'));
    }
  }

  ngOnInit() {
    this.adService.getAdverstisments(this.page).subscribe((data: any) => {
      this.cmsData = data;
    });
    this.loadAffairs(0);
    this.loadRelatedTo(0);
    this.loadContactThrough(0);
    this.loadCompanieInfo(0);
    this.getContentB2s();
    this.getcontacto();
    console.log(this.getcontacto());
    this.nombre = '0';
    this.apellido = '0';
    this.correo = '0';
    this.telefono = '0';
    this.telefono_2 = '0';
    this.numero_tele = '0';
    this.indicativo = '0';
    this.dir = '0';
    this.horario = '0';
    this.ext = '0';
    if (localStorage.getItem('userData') !== null) {
      this.initModelContacUs();
      this.getUserInfo(this.userData.userId);
    }

  }

  onSubmit(form) {
    this.selectedCompany = localStorage.getItem('selectedCompany');
    this.disabledButton = true;
    this.registerForm = this.formBuilder.group({
      email: [form.value.email, [Validators.email]],
      phone: [form.value.phone, [Validators.maxLength(10), Validators.minLength(10)]],
    });

    for (const campo in form.controls) {
      if (form.controls[campo].errors != null) {
        if (form.controls[campo].errors.required) {
          this.showError(campo, 'Es obligatorio');
        }
      }
    }
    for (let propiedad in this.registerForm.controls) {
      if (this.registerForm.controls[propiedad].errors != null) {
        if (this.registerForm.controls[propiedad].errors.email != null) {
          if (this.registerForm.controls[propiedad].errors.email) {
            this.showError('Correo', 'Formato invalido');
            this.disabledButton = false;
          }
        }
        if (this.registerForm.controls[propiedad].errors.maxlength != null) {
          if (this.registerForm.controls[propiedad].errors.maxlength) {
            this.showError('Teléfono', 'El Teléfono Móvil debe tener 10 digitos');
            this.disabledButton = false;
          }
        }
        if (this.registerForm.controls[propiedad].errors.minlength != null) {
          if (this.registerForm.controls[propiedad].errors.minlength) {
            this.showError('Teléfono', 'El Teléfono Móvil debe tener 10 digitos');
            this.disabledButton = false;
          }
        }
      }
    }
    if (form.valid && this.registerForm.valid) {
      this.disabledButton = true;
      this.sendRequest(form);
    }
  }

  loadAffairs(n) {
    this.affairService.getService().subscribe(
      result => {
        if (result.code !== 200) {
          this.affairs = result.Matters;
        } else {
          n++;
          if (n < 4) {
            this.loadAffairs(n);
          }
        }
      },
      error => {
        // console.log(error as any);
      }
    );
  }

  getcontacto() {
    this.contactService.contactos_para();
    console.log()
  }

  loadRelatedTo(n) {
    this.relatedtoService.getService().subscribe(
      result => {
        if (result.code !== 200) {
          this.relatedto = result;
        } else {
          n++;
          if (n < 4) {
            this.loadRelatedTo(n);
          }
        }
      },
      error => {
        // console.log(error as any);
      }
    );
  }

  loadContactThrough(n) {
    this.contactThroughService.getService().subscribe(
      result => {
        if (result.code !== 200) {
          this.contactThroughs = result;
        } else {
          n++;
          if (n < 4) {
            this.loadContactThrough(n);
          }
        }
      },
      error => {
        // console.log(error as any);
      }
    );
  }

  loadCompanieInfo(n) {
    this.companieInfoService.getService().subscribe(
      result => {
        if (result.code !== 200) {
          this.companieInfo = result;
        } else {
          n++;
          if (n < 4) {
            this.loadCompanieInfo(n);
            console.log(this.loadCompanieInfo(n));
          }
        }
      },
      error => {
      }
    );
  }

  sendRequest(form) {
    this.contactService.getService(form).subscribe(
      result => {

        if (!result.error) {
          this.contact = result;
          this.disabledButton = false;
          this.showSucess('Mensaje', 'El correo se ha enviado correctamente');
          form.reset();
          this.resetSelects();
          if (localStorage.getItem('userData') !== null) {
            this.getUserInfo(this.userData.userId);
          }
        } else {
          this.contact = result.data;
        }
      },
      error => {

      }
    );
  }

  showError(title: string, message: string) {
    if (message.length > 0) {
      this.toastr.error(message,
        title, {
        enableHtml: true,
        closeButton: true,
      });
    }
  }

  showSucess(title: string, message: string) {
    if (message.length > 0) {
      this.toastr.success(message,
        title, {
        enableHtml: true,
        closeButton: true,
      });
    }
  }

  initModelContacUs() {
    this.contactUsModel.name = this.userData.contactFirstName.trim();
    this.contactUsModel.surname = this.userData.contactLastName.trim();
    this.contactUsModel.email = this.userData.contactEmail.trim();
  }

  getUserInfo(userId) {
    this._authService.getUserInfo(userId)
      .subscribe((userData: UserInfo) => {
        this.contactUsModel.name = userData.userInfo.firstName;
        this.contactUsModel.surname = userData.userInfo.lastName;
        this.contactUsModel.email = userData.userInfo.email;
        this.contactUsModel.city = userData.userInfo.cityName;
        this.contactUsModel.phone = userData.userInfo.movil;
        this.contactUsModel.indicative = userData.userInfo.movilIndicative;
      });
  }

  resetSelects() {
    let allSelects = document.getElementsByTagName('select');
    for (let i = 0; i < allSelects.length; i++) {
      allSelects[i].value = '';
    }
  }

  getContentB2s() {
    this._hedaerService.getAGPParams(this.selectedCompany)
      .subscribe((contentReponse: any) => {
        console.log(contentReponse);
        return this.b2sContent = contentReponse;
      });
  }
}

export interface UserInfo {
  userInfo: {
    firstName: string,
    lastName: string,
    email: string,
    cityName: string,
    movil: string,
    movilIndicative: string
  };
}
