<!-- Main Content -->
<main role="main" class="flex-shrink-0 contact__no-login">
  <!-- Begin Begin Page Content -->
  <div class="container pt-3">

    <!-- Title -->
    <div class="d-sm-flex align-items-center justify-content-between mb-2">
      <!-- <h1 class="font-weight-bold h3 mb-0 text-gray">Contáctenos</h1> -->
    </div>
    <!-- End Title -->

    <!-- Breadcrumb -->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['','login']" data-toggle="tooltip" data-placement="bottom"
            data-html="true" title="Iniciar Sesión"><i class="far fa-user"></i></a></li>
        <!-- <li class="breadcrumb-item active" aria-current="page">Contáctenos</li> -->
      </ol>
    </nav>
    <!-- End Breadcrumb -->

    <div class="row">

      <!-- Header -->
      <div class="col-md-12">
        <div class="banner">
          <img class="img-fluid" src="../assets/images/contactenos.jpg" alt="">
        </div>
        <h4 class="font-weight-bold text-primary">Gracias por Contactarnos</h4>
        <p class="text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt
          ut labore et
          dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
          ea commodo consequat.</p>
      </div>
      <!-- End Header -->

      <!-- Form -->
      <div class="col-md-8 mb-5 order-md-1">
        <h4 class="font-weight-bold text-primary">Escríbenos</h4>
        <form class="form__default">
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-user icon-form"></i></span>
                </div>
                <input type="text" id="inputName" class="form-control" placeholder="Escríbe tu nombre" required
                  autofocus>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="far fa-user icon-form"></i></span>
                </div>
                <input type="text" id="inputLastName" class="form-control" placeholder="Escríbe tu(s) apellido(s)"
                  required autofocus>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="far fa-envelope icon-form"></i></span>
              </div>
              <input type="email" id="inputEmail" class="form-control" placeholder="Escríbe tu correo electrónico"
                required autofocus>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-mobile-alt icon-form"></i></span>
                </div>
                <input type="text" id="inputMobile" class="form-control" placeholder="Escríbe tu teléfono móvil"
                  required autofocus>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-globe-americas icon-form"></i></span>
                </div>
                <input type="text" id="inputCountry" class="form-control" placeholder="Escríbe tu país de residencia"
                  required autofocus>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="far fa-building icon-form"></i></span>
                </div>
                <input type="text" id="inputCompany" class="form-control" placeholder="Escríbe el nombre de la empresa"
                  autofocus>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fa fa-briefcase icon-form"></i></span>
                </div>
                <input type="text" id="inputPosition" class="form-control" placeholder="Escríbe tu cargo" autofocus>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-envelope-open-text"></i></span>
                </div>
                <select class="custom-select" id="listSubject" autofocus>
                  <option value="">Elije un Asunto</option>
                  <option>Consulta</option>
                  <option>Queja o Reclamo</option>
                  <option>No Puedo Acceder</option>
                  <option>Otro</option>
                </select>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-list-ul"></i></span>
                </div>
                <select class="custom-select" id="listSubject" autofocus>
                  <option value="">Relaciondo con</option>
                  <option>Aplicativo</option>
                  <option>Producto(s) o Servicio(s)</option>
                  <option>Otro(s)</option>
                </select>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="far fa-comment-dots"></i></span>
              </div>
              <textarea id="textareaMessage" class="form-control" placeholder="Describe tu asunto..."
                autofocus></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="far fa-comments"></i></span>
                </div>
                <select class="custom-select" id="listContactMe" autofocus>
                  <option value="">Elije vía de contacto</option>
                  <option>Telefónica</option>
                  <option>Email</option>
                  <option>Whatsapp</option>
                </select>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="far fa-calendar-alt"></i></span>
                </div>
                <select class="custom-select" id="listSubject" autofocus>
                  <option value="">Elije un horario</option>
                  <option>Mañana</option>
                  <option>Tarde</option>
                  <option>Noche</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="checkAcepto" required autofocus>
                <label class="form-check-label" for="checkAcepto"><a href="#">Acepto términos y condiciones</a></label>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="checkAutorizo" required autofocus>
                <label class="form-check-label" for="checkAcepto"><a href="#">Autorizo el tratamiento de
                    datos</a></label>
              </div>
            </div>
          </div>
          <a href="#" class="btn btn-primary btn-icon-split">
            <span class="text">Enviar Mensaje</span>
            <span class="icon text-white-50">
              <i class="fa fa-paper-plane"></i>
            </span>
          </a>
        </form>
      </div>
      <!-- End Form -->

      <!-- Contact Information -->
      <div class="col-md-4 order-md-2 mb-5">
        <!-- <h4 class="font-weight-bold text-primary">Contáctenos</h4> -->
        <div class="card mb-4 py-0 border-left-primary">
          <div class="card-body">
            <span class="text-muted"><i class="fas fa-phone icon-sidebar"></i> 57 (2) 524 1860</span><br>
            <span class="text-muted"><i class="fas fa-mobile-alt icon-sidebar"></i> +57 350 787 2647</span><br>
            <span class="text-muted"><i class="fas fa-mobile-alt icon-sidebar"></i> +57 301 445 3836</span><br>
            <span class="text-muted"><i class="far fa-envelope icon-sidebar"></i>
              proyectos@e-solutions.com.co</span><br>
            <span class="text-muted"><i class="fas fa-map-pin icon-sidebar"></i> Calle 35AN#2Bn-160</span><br>
            <span class="text-muted"><i class="fas fa-map-marker icon-sidebar"></i> Cali-Colombia</span>
          </div>
        </div>
      </div>
      <!-- End Contact Information -->

    </div>
  </div>
  <!-- End Begin Page Content -->
</main>
<!-- Main Content -->

<!-- Footer -->
<footer class="footer__no-login mt-auto py-3 bg-dark-gray text-white text-center">
  <div class="container">
    <small>
      &copy; 2019 - Portal Proveedores Siesa |
      <a [routerLink]="['','login']">Iniciar Sesión</a> |
      <a [routerLink]="['','contact']">Contáctenos</a> |
      <a [routerLink]="['','search-company']">Registro de Proveedores</a> |
      <a [routerLink]="['','privacy']">Política de Tratamiento de Datos</a> |
      <a [routerLink]="['','terms']">Términos y Condiciones</a>
    </small>
  </div>
</footer>
<!-- End Footer -->