<footer *ngIf="userLogged">
  <div class="footer mt-auto py-4 pt-md-5 pb-md-4 border-top">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md">
          <!-- <h5 class="font-weight-bold"> Certificados</h5> -->
          <!-- <ul class="list-unstyled mt-3">
            <li><a [routerLink]="['','profile']">Mis Datos</a></li>
            <li *ngIf="b2sContent"><a *ngIf="b2sContent.descarga_certificado > 0"
                [routerLink]="['','download-certificado']">Descargar Certificados</a></li>
            <p>Codigo descargue : {{descargarCertificado}}</p>
            <p> JSON {{b2sContent.descarga_certificado | json}}</p>
          </ul> -->
        </div>
        <div class="col-12 col-md">
          <h5 class="font-weight-bold">Información</h5>
          <ul class="list-unstyled mt-3">
            <li><a [routerLink]="['','about-us']">Quienes Somos</a></li>
            <!-- <li><a [href]="infoPage['PAG-9']?.direccion_uri" target="_blank">Quienes Somos</a></li>  LINK con error-->
            <li *ngIf="b2sContent"><a *ngIf="b2sContent.contact > 0" [routerLink]="['','contact-us']">Contáctenos</a>
            </li>
            <li><a [routerLink]="['','privacy-policy']">Política de Tratamiento de Datos</a></li>
            <li><a [routerLink]="['','terms-and-conditions']">Términos y Condiciones</a></li>
          </ul>
        </div>
        <div class="col-12 col-md">
          <h5 class="font-weight-bold">Navega</h5>
          <ul class="list-unstyled mt-3">
            <!--<li><a [routerLink]="['','late-payments']">Pagos Pendientes</a></li>-->
            <li><a [routerLink]="['/payments-made/',30]">Facturas Pagadas</a></li>
            <div *ngIf="b2sContent && b2sContent.certificate > 0">
              <li><a [routerLink]="['','certificates']">Certificados de Retención</a></li>
            </div>
            <li><a [routerLink]="['/account-statements',30]">Facturas Pendientes de Pago</a></li>
            <li *ngIf="b2sContent"> <a *ngIf="b2sContent.orden_compra > 0"
                [routerLink]="['/purchase-order', 0 ]">Ordenes de Compra</a> </li>
            <li *ngIf="b2sContent"> <a *ngIf="b2sContent.orden_ticket > 0"
                [routerLink]="['/purchase-tickets', 0 ]">Entradas de Almacén por Compra</a> </li>
            <li *ngIf="b2sContent"> <a *ngIf="b2sContent.orden_notas_debito > 0"
                [routerLink]="['/notes-debit', 0 ]">Notas Debito</a> </li>
            <li *ngIf="b2sContent"> <a *ngIf="b2sContent.mostrar_contratos_proveedores > 0"
                [routerLink]="['','supplier-contracts']">Contratos de Proveedoress</a> </li>

          </ul>
        </div>
        <!-- <div class="col-12 col-md">
                    <h5 class="font-weight-bold">Contáctenos</h5>
                    <ul class="list-unstyled mt-3">
                        <div *ngIf="footerInfo">
                            <li *ngIf="footerInfo.phone"><i class="fas fa-phone icon-footer"></i> {{ footerInfo.phone }}</li>
                            <li *ngIf="footerInfo.phone2"><i class="fas fa-mobile-alt icon-footer"></i> {{ footerInfo.phone2 }}</li>
                            <li *ngIf="footerInfo.phone3"><i class="fas fa-mobile-alt icon-footer"></i> {{ footerInfo.phone3 }}</li>
                            <li *ngIf="footerInfo.email"><i class="far fa-envelope icon-footer"></i> {{ footerInfo.email }}</li>
                            <li *ngIf="footerInfo.address"><i class="fas fa-map-pin icon-footer"></i> {{ footerInfo.address }}</li>
                            <li *ngIf="footerInfo.location"><i class="fas fa-map-marker icon-footer"></i> {{ footerInfo.location }}</li>
                        </div>
                    </ul>
                </div> -->
      </div>
    </div>
  </div>
  <div class="footer__bottom mt-auto py-3 text-center">
    <div class="container">
      <small>Copyright 2022 © Siesa E-Commerce - Todos los derechos reservados.</small>
    </div>
  </div>
</footer>

<!-- <footer *ngIf="!userLogged" class="mastfoot mt-auto py-3 text-center">
  <div class="container">
    <small>
      &copy; 2019 - Portal Proveedores Siesa |
      <a class="mastfoot--link" [routerLink]="['','login']">Iniciar Sesión</a> |
      <a class="mastfoot--link" *ngIf="b2sContent && b2sContent.contact > 0" [routerLink]="['','contact-us']">Contáctenos |</a>
      <a class="mastfoot--link" *ngIf="b2sContent && b2sContent.provider > 0" [routerLink]="['','search-company']">Registro de Proveedores
        |</a>
      <a class="mastfoot--link" [routerLink]="['','privacy']">Política de Tratamiento de Datos</a> |
      <a class="mastfoot--link" [routerLink]="['','terms']">Términos y Condiciones</a>
    </small>
  </div>
</footer> -->

<app-footer-logout *ngIf="!userLogged"></app-footer-logout>