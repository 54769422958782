import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '../layout/login/login.component';
import { SupplierRegistrationComponent } from '../layout/supplier-registration/supplier-registration.component';
import { TermsComponent } from '../layout/terms/terms.component';
import { PrivacyComponent } from '../layout/privacy/privacy.component';
import { BrowserNotSupportedComponent } from '../browser-not-supported/browser-not-supported.component';
import { OfflineExpectationComponent } from '../layout/offline-expectation/offline-expectation.component';
import { ModulesComponent } from '../core/components/modules.component';
import { NotFoundComponent } from '../core/components/not-found/not-found.component';
import { AuthGuard } from '../gards/auth.gard';
import { InternalErrorComponent } from '../core/components/internal-error/internal-error.component';
import { PasswordComponent } from '../password/password.component';
import { ContactUsComponent } from '../layout/contact-us/contact-us.component';
import { SearchCompanyComponent } from '../layout/search-company/search-company.component';
import { SupplierRegistrationCompanyComponent } from '../layout/supplier-registration-company/supplier-registration-company.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'supplier-registration', component: SupplierRegistrationComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'browser-not-supported', component: BrowserNotSupportedComponent },
  { path: 'offline-expectation', component: OfflineExpectationComponent },
  { path: 'password-recovery', component: PasswordComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'search-company', component: SearchCompanyComponent },
  { path: 'supplier-registration/company/:nit', component: SupplierRegistrationCompanyComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: '',
    component: ModulesComponent,
    loadChildren: () => import('../core/components/modules.module').then(m => m.ModulesModule),
    canActivate: [AuthGuard]
  },
  { path: '500', component: InternalErrorComponent },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404', pathMatch: 'full' },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
