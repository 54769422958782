import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../common/services/header.service';

declare var $: any;

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss']
})
export class ModulesComponent implements OnInit {
  b2sContent: any;
  public selectedCompany;

  // tslint:disable-next-line:variable-name
  constructor(private _hedaerService: HeaderService) { }
  ngOnInit() {
    this.selectedCompany = localStorage.getItem('selectedCompany');
    $('[data-toggle="tooltip"]').tooltip();
    this.getContentB2s();
  }

  getContentB2s() {
    this._hedaerService.getAGPParams(this.selectedCompany)
      .subscribe((contentReponse: any) => {
        return this.b2sContent = contentReponse;
      });
  }
}
