<section role="main" class="inner cover py-0 px-3 content__main">
  <div class="container py-3">
    <!-- 404 Error Text -->
    <div class="text-center mt-3">
      <img class="mb-4" src="../../assets/images/server.svg" alt="" width="175" height="auto">
      <h1 class="mx-auto text-info" data-text="Ups">¡Oops!</h1>
      <h4 class="text-gray mb-3">Error en el Servidor</h4>
      <p class="text-gray mb-5">Lo sentimos, el servidor ha presentado un problema en este momento. Por favor intenta de nuevo más tarde</p>
      <a [routerLink]="['','login']"><i class="fas fa-home"></i> Volver al Inicio</a>
    </div>
  </div>
</section>
