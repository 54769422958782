import { NgModule } from '@angular/core';

import {NgbModule, NgbRatingModule} from '@ng-bootstrap/ng-bootstrap';
import { NgCircleProgressModule } from 'ng-circle-progress';

@NgModule({
  declarations: [ ],
  imports: [
    NgbModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300
    }),
    NgbRatingModule
  ],
  exports: [
    NgbModule,
    NgCircleProgressModule,
    NgbRatingModule
  ],
  providers: [
  ],
})
export class ThirdPartyModulesModule { }
