import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '@app/common/services/env.service';
import { constants } from '@app/common/constants/app.constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private contentHeader: any = {};

  constructor(private http: HttpClient,
    private env: EnvService) {
  }

  getAGPParams(company) {
    const urlHeader = constants.config.apiUrl + constants.config.getContentAGP;
    this.contentHeader = this.http.get(urlHeader, {
      withCredentials: true,
      headers: {
        company: company
      }
    });
    return this.contentHeader;
  }

  getInitData(): Observable<any> {
    const urlHeader = this.env.apiGatewayFront + constants.config.getInitData;
    this.contentHeader = this.http.get(urlHeader);
    return this.contentHeader;
  }

}
