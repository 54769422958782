import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../../common/services/auth.service';
import { Router, RouterStateSnapshot } from '@angular/router';
import { first, timeout } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  blogEvent: any;
  logo: string;
  jsonUser: any;
  nameUser: string;
  nameSupplier: string;
  // tslint:disable-next-line:no-input-rename
  @Input('b2sContent')
  public b2sContent: any;

  // tslint:disable-next-line:variable-name
  constructor(private _authService: AuthService, private _router: Router, private _route: ActivatedRoute,) {
    this._route.params
      .subscribe(params => {

        const json = {
          data: 20,
        };
        this.getLoginService(json);

      });
  }

  ngOnInit() {
    // this.logo = localStorage.getItem('logo');
    this.jsonUser = JSON.parse(localStorage.getItem('userData'));
    console.log(this.jsonUser);
    this.nameUser = this.jsonUser.contactFirstName;
    this.nameSupplier = this.jsonUser.razon_social;
  }

  logout() {
    this._authService.logoutService();
    localStorage.removeItem('authenticate');
    localStorage.removeItem('session_token');
    localStorage.removeItem('userData');
    localStorage.removeItem('descarga_certificado');
    this._router.navigate(['#/login']);
  }

  getLoginService(params) {
    this._authService.getDataLogin(params).pipe(first(), timeout(1000)).subscribe(
      (response: any) => {
        if (response.error) {
          this.logo = localStorage.getItem('logo');
        } else {
          this.logo = response.logo;
        }
      }, (error: any) => {
        this.logo = localStorage.getItem('logo');
      }
    );
  }

}
