<div class="cover-container d-flex pass-recover h-100 p-0 mx-auto flex-column">
  <div class="cover-container d-flex w-50 h-100 p-0 mx-auto flex-column">

    <header class="masthead mb-auto">
      <div class="inner">
      </div>
    </header>
    <div class="col-12 col-lg-12 login-form-column">
      <section class="login-form-container">
        <div class="row">
          <div class="col-10 col-md-8 col-lg-6 mx-auto">
            <form novalidate="" novalidate="" #forma="ngForm" (ngSubmit)="recoveryPassword(forma)" class="form-signin">
              <div class="text-center mb-4">
                <img class="mb-4" src="{{logo}}" alt="" width="200" height="auto">
                <h4 class="text-center mt-4">¿Olvidó su contraseña?</h4>
                <div *ngIf="contentCmsIntro" [innerHTML]="contentCmsIntro.contenido" class="text-left mt-4">
                </div>
              </div>

              <div class="form-label-group">
                <input ngModel type="email" name="inputEmail" class="form-control" placeholder="Correo Electronico"
                  required autofocus>
              </div>

              <div class="form-group">
                <label>Seleccione la Compañía</label>
                <select [(ngModel)]="dataForm.inputCompany" class="custom-select custom-select-lg"
                  #inputCompany="ngModel" name="inputCompany" placeholder="Compañia">
                  <option *ngFor="let tercero of company | keyvalue" value="{{tercero.key}}">{{tercero.value}}
                  </option>
                </select>
                <div *ngIf="inputCompany.invalid">
                  <div class="invalid-feedback" *ngIf="inputCompany.errors.required">
                    Ingrese la Compañia.
                  </div>
                </div>
              </div>

              <re-captcha ngModel name="capcha" required></re-captcha> <br>

              <!-- <br> -->
              <button type="submit" class="btn btn-primary btn-block btn-lg">Restablecer</button>

              <a [routerLink]="['','login']" class="btn btn-link btn-block">Iniciar Sesión</a>

              <div *ngIf="contentCmsTips" [innerHTML]="contentCmsTips.contenido" class="text-left mt-4"></div>

            </form>
          </div>
        </div>
      </section>
    </div>

    <!-- 
    <footer class="mastfoot mt-auto py-3 text-center">
      <div class="inner">
        <small>
          &copy; 2019 - Portal Proveedores Siesa |
          <a class="mastfoot--link" [routerLink]="['','login']">Iniciar Sesión</a> |
          <a class="mastfoot--link" [routerLink]="['','contact']">Contáctenos</a> |
          <a class="mastfoot--link" [routerLink]="['','supplier-registration']">Registro de Proveedores</a> |
          <a class="mastfoot--link" [routerLink]="['','privacy']">Política de Tratamiento de Datos</a> |
          <a class="mastfoot--link" [routerLink]="['','terms']">Términos y Condiciones</a>
        </small>
      </div>
    </footer> -->



  </div>

  <!-- <app-footer></app-footer> -->
</div>