<app-header *ngIf="userLogged" [b2sContent]="b2sContent"></app-header>
<app-header-public *ngIf="!userLogged"></app-header-public>
<section role="main" class="inner cover py-0 px-3 content__main">
  <div class="container py-5">
      <div *ngIf="cmsData" class="banner">
        <app-advertisements *ngIf="cmsData.espacios[1]"  [typeContent]="'espacios'" [positions]="1" [contentData]="cmsData"></app-advertisements>
      </div>
  </div>
</section>
<app-footer [b2sContent]="b2sContent"></app-footer>
