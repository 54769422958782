
<footer *ngIf="!userLogged" class="mastfoot mt-auto py-3 text-center">
  <div class="container">
    <small>
      &copy; 2019 - Portal Proveedores Siesa |
      <a class="mastfoot--link" [routerLink]="['','login']">Iniciar Sesión</a> |
      <a class="mastfoot--link" *ngIf="b2sContent && b2sContent.contact > 0" [routerLink]="['','contact-us']">Contáctenos |</a>
      <a class="mastfoot--link" *ngIf="b2sContent && b2sContent.provider > 0" [routerLink]="['','search-company']">
        Registro de Proveedores |</a>
      <a class="mastfoot--link"  [routerLink]="['','privacy']">Política de Tratamiento de Datos |</a> 
      <a class="mastfoot--link"  [routerLink]="['','terms']">Términos y Condiciones</a>
    </small>
  </div>
</footer>
