import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { EnvService } from '@app/common/services/env.service';
import { constants } from '@app/common/constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class OfflineService {

  constructor(private http: HttpClient, private env: EnvService) {
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  getStatusPage(): Observable<any> {
    const url = this.env.apiGatewayFront + constants.config.pageOffline;
    return this.http.get<any>(url)
      .pipe(
        retry(1)
      );
  }
}
