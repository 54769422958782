<table>
  <tr>
    <td>1</td>
    <td>2</td>
    <td>3</td>
    <td>4</td>
    <td>5</td>
    <td>6</td>
    <td>7</td>
  </tr>
  <tr>
    <td>nombre</td>
    <td>otro nomber</td>
    <td>tres</td>
    <td>cuatro</td>
    <td>cinco</td>
    <td>seis</td>
    <td>siete</td>
  </tr>
  <tr>
    <td>nombre</td>
    <td>otro nomber</td>
    <td>tres</td>
    <td>cuatro</td>
    <td>cinco</td>
    <td>seis</td>
    <td>siete</td>
  </tr>
  <tr>
    <td>nombre</td>
    <td>otro nomber</td>
    <td>tres</td>
    <td>cuatro</td>
    <td>cinco</td>
    <td>seis</td>
    <td>siete</td>
  </tr>
</table>
