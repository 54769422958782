<main role="main" class="content__main">

  <!-- Container -->
  <div class="container">

    <div class="row">
      <div class="col-md-12">

        <div *ngIf="cmsData">
          <app-advertisements *ngIf="cmsData.espacios[1]" [typeContent]="'espacios'" [positions]="1"
            [contentData]="cmsData">
          </app-advertisements>
        </div>

      </div>
      <div class="col-md-12" style="text-align:center;">
        <img class="fuera_servicio3" src="../../assets/images/sin_servicio3.png">
      </div>
    </div>

  </div>

</main>