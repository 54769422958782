import { environment } from '../../../environments/environment';

export const constants = {
  config: {
    // agp_app_login: 'entry_point/e_get_cms_login/',
    adverstismentApi: 'entry_point/e_get_cms_space/?&ad=0',
    adverstismentCmsSpaces: 'entry_point/e_get_cms_space/?ubicacion=',
    apiGatewayFront: environment['apiGatewayFront'],
    apiUrl: environment['apiUrl'],
    certificateYear: 'entry_point/agp_certificado_fecha/',
    createPayment: 'entry_point/agp_create_payment/',
    createUser: 'entry_point/e_create_update_user_by_admin/',
    enableOrDisableUser: 'entry_point/e_enable_user_admin/',
    emailValidator: 'entry_point/e_validate_email/',
    getAffairs: 'entry_point/agr_get_affairs/',
    getBlogCategory: 'blog/blog-list?category',
    getBlogList: 'blog/blog-list?limit=5&page=1',
    getBlogPanel: 'blog/blog-list-panel',
    getCertificadosCamaraComercio: 'consult-b2s/listado-certificado-empresa',
    getCidudadesAplicacionCertificados: 'entry_point/agp_consulta_ciudades_certificados/',
    getClaseRetencionCertificados: 'entry_point/agp_consulta_clase_retencion_certificados/',
    getContactThrough: 'entry_point/agr_get_contact_through/',
    getContentAGP: 'entry_point/agp_get_content/',
    getContent_loginAGP: 'entry_point/agp_content_login/',
    getContentCms: 'entry_point/e_get_cms_space/?ubicacion',
    getContentCmsInfo: 'entry_point/e_get_cms_space/',
    getContactosPara: 'entry_point/agp_contactos_param/',
    getCompanieInfo: 'entry_point/agp_get_companie_info/',
    getCompanySuplier: 'entry_point/agp_get_company_info_erp/',
    getCompanySuplierRegister: 'supplier-registration/get-company',
    getDataAccountStatements: 'entry_point/agp_consulta_cuentas_x_pagar/',
    getCidudadesAplicacionCertificadosRete: 'entry_point/agp_consulta_ciudades_certificados_reteFuente/',
    getCidudadesAplicacionCertificadosIva: 'entry_point/agp_consulta_ciudades_certificados_reteIva/',
    getCidudadesAplicacionCertificadosIca: 'entry_point/agp_consulta_ciudades_certificados_reteIca/',
    getAgpEntradaAlmacen: 'entry_point/agp_entrada_almacen/', // dato nuevo -----------
    getAgpDetalleEntradaAlmacen: 'entry_point/agp_detalle_entrada_almacen/', // dato nuevo -----------
    getDetailPurchaseOrder: 'entry_point/agp_consulta_orden_compra_detalle/',
    getDetailSupplierContracts: 'consult-b2s/consulta-detalle-contratos-proveedores',
    getInitData: 'entry_point/e_get_companies/',
    getLocationByText: 'entry_point/agp_consulta_ciudades_certificados/',
    getPaymentsMade: 'entry_point/agp_consulta_facturas_canceladas_b2s/',
    getPaymentsMade_deta: 'entry_point/agp_consulta_detalle_facturas_canceladas_b2s/',
    getPdfEgresos: 'entry_point/agp_comprobante_egreso_pdf/',
    getPdfOrdenes: 'entry_point/agp_comprobante_ordenes_pdf/',
    getPdfTickets: 'entry_point/agp_entrada_compra_pdf/',
    getNotasDebito: 'entry_point/agp_notas_debito/',
    getAgpDetalleDebito: 'entry_point/agp_detalle_notas_debito/',
    getPostBlog: 'blog/get-post?slug',
    getProveedores_list: 'entry_point/agp_validate_proveedores_register/',
    getProvidersQualification: 'entry_point/agp_consulta_calificacion_proveedores/',
    getPurchaseOrder: 'entry_point/agp_consulta_orden_compra/',
    getRelatedTo: 'entry_point/agr_get_related_to/',
    getSearchBlog: 'blog/blog-list?limit=5&page=1&searchword',
    getSupplierContracts: 'entry_point/agp_consulta_contratos_proveedores/',
    getTagBlog: 'blog/blog-list?limit=5&page=1&tag',
    getTercerosCertificados: 'entry_point/agp_terceros_certificados/',
    getTypeDocumentsUser: 'entry_point/agp_get_tipo_documentos/',
    getUserInfo: 'entry_point/agr_user_info/',
    getValidateCertifiedRtefuenteGeneration: 'entry_point/agp_validar_generacion_rte_fuente_pdf/',
    getValidateCertifiedRteicaGeneration: 'entry_point/agp_validar_generacion_rte_ica_pdf/',
    getValidateCertifiedRteIvaGeneration: 'entry_point/agp_validar_generacion_rte_iva_pdf/',
    identificationValidator: 'entry_point/e_user_by_identification/',
    infoPage: 'entry_point/agp_info_page/',
    limitBlog: '&limit=5&page=1',
    myUsersList: 'entry_point/e_users_related_to_admin/',
    pageOffline: 'entry_point/agp_app_params/',
    recoverPassword: 'entry_point/agp_update_password/',
    rteFuentePdf: 'entry_point/agp_rte_fuente_pdf/',
    rteIcaPdf: 'entry_point/agp_rte_ica_pdf/',
    rteIvaPdf: 'entry_point/agp_rte_iva_pdf/',
    sendDataCertificates: 'entry_point/agp_register_supplier/',
    sendInvitation: 'user-admin/send-invitation',
    sendInvitationUser: 'entry_point/agr_send_invitation_user/',
    sendMailContact: 'entry_point/agp_get_contact_through/',
    siteKeyCaptcha: environment['siteKeyCaptcha'],
    supplierGetCities: 'entry_point/agp_get_cities/',
    supplierGetEconomicActivities: 'entry_point/agp_get_economic_activities/',
    supplierGetEconomicSubActivities: 'supplier-registration/get-economic-subactivities',
    supplierGetTaxRegimes: 'entry_point/agp_get_tax_regimes/',
    supplierValidateContactIdentification: 'entry_point/agp_validate_contact_identification/',
    supplierValidateEmailContactSupplier: 'entry_point/agp_validate_email_contact_supplier/',
    supplierValidateNitSupplierRegister: 'entry_point/agp_validate_nit_supplier_register/',
    supplierRegister: 'entry_point/agp_register_supplier_new/',
    supplierRegisterProblems: 'entry_point/agp_register_supplier_problems/',
    updatePassword: 'entry_point/e_update_password/',
    updateUser: 'entry_point/e_update_user/',
    userData: 'entry_point/e_preload_user_created_by_admin/',
    userLogin: 'entry_point/e_user_login/',
    userLogout: 'entry_point/e_logout/',
    validatePayment: 'entry_point/agp_validate_payment/',
    getListaRetencionFuente: 'entry_point/agp_getListaRetencionFuente/',
    getListaRetencionIva: 'entry_point/agp_getListaRetencionIva/',
    getListaRetencionIca: 'entry_point/agp_getListaRetencionIca/',

  }
};
