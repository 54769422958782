<nav class="navbar navbar-expand-md navbar-dark fixed-top bg-primary topbar shadow">

  <a class="navbar-brand" [routerLink]="['/login']">
    <img class="logo" src="{{logo}}" alt="Logo">
  </a>

  <button id="sidebarToggleTop" class="navbar-toggler btn btn-link d-md-none rounded-circle" type="button"
    data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
    aria-label="Toggle navigation">
    <i class="fa fa-bars"></i>
  </button>

  <div class="collapse navbar-collapse" id="navbarCollapse">

    <ul class="navbar-nav mr-auto">
    </ul>

    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['','login']">Iniciar Sesión</a>
      </li>
      <li class="nav-item">
        <a *ngIf="contentProvider > 0" class="nav-link" [routerLink]="['','search-company']" >Registro de proveedor</a>
      </li>
    </ul>
  </div>
</nav>