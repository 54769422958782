import { Component, OnInit, ViewEncapsulation, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { OfflineService } from '../../common/services/offline.service';
import { HeaderService } from '../../common/services/header.service';
import { AdverstismentService } from '../../common/services/adverstisment.service';
declare var $: any;

@Component({
  selector: 'app-offline-expectation',
  templateUrl: './offline-expectation.component.html',
  styleUrls: ['./offline-expectation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OfflineExpectationComponent implements OnInit {
  endOffline: string;
  flagOffline: boolean;
  page = 'PAG-67';
  cmsData: any;
  public selectedCompany;

  constructor(private _router: Router,
    private _offlineService: OfflineService,
    private _renderer: Renderer2,
    private headerService: HeaderService,
    public _adService: AdverstismentService) {

    this._router.events.subscribe(event => {
      let appOfflineExpectation = document.getElementsByTagName('app-offline-expectation');
      if (appOfflineExpectation.length >= 1) {
        this._renderer.addClass(document.body, 'offline-wrapper');
      }
    });
  }

  ngOnInit() {
    this.selectedCompany = localStorage.getItem('selectedCompany');
    this.headerService.getAGPParams(this.selectedCompany).subscribe(statusPage => {
      this.flagOffline = statusPage.pageOnline;
      this.endOffline = statusPage.offlineTime;

      $('#clock').countdown(this.endOffline, function (event) {
        var $this = $(this).html(event.strftime(''
          + '<div class="simply-section d-flex align-content-center justify-content-center flex-wrap white-text-color"><span class="w-100 simply-amount">%w</span> semanas </div>'
          + '<div class="simply-section d-flex align-content-center justify-content-center flex-wrap white-text-color"><span class="w-100 simply-amount">%d</span> dias </div>'
          + '<div class="simply-section d-flex align-content-center justify-content-center flex-wrap white-text-color"><span class="w-100 simply-amount">%H</span> hr </div>'
          + '<div class="simply-section d-flex align-content-center justify-content-center flex-wrap white-text-color"><span class="w-100 simply-amount">%M</span> min </div>'
          + '<div class="simply-section d-flex align-content-center justify-content-center flex-wrap white-text-color"><span class="w-100 simply-amount">%S</span> sec </div>'));
      });

      if (this.flagOffline) {
        this._router.navigate(['/login']);
      }

    });
    this._adService.getAdverstisments(this.page).subscribe((data: any) => {
      this.cmsData = data;
    });
  }

}
