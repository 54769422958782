import { Component, OnInit, Input } from '@angular/core';
import { CompanieInfoService } from '@app/common/services/companie-info.service';
import { AdverstismentService } from '@app/common/services/adverstisment.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  contact: any;
  userLogged: boolean;
  footerInfo: any;
  private page = ['PAG-9'];
  private infoPage = {};
  descargarCertificado: any;
  // tslint:disable-next-line:no-input-rename
  @Input('b2sContent')
  public b2sContent: any;



  // tslint:disable-next-line:variable-name
  constructor(private _companieInfo: CompanieInfoService,
    // tslint:disable-next-line:variable-name
              public _adService: AdverstismentService) {
    this.userLogged = JSON.parse(localStorage.getItem('authenticate'));
  }

  ngOnInit() {
    this.getFooterInfo();
    this.getInfoPage();
    this.descargarCertificado = localStorage.getItem('descarga_certificado');
    console.log(localStorage.getItem('descarga_certificado'));
  }

  getFooterInfo() {
    this._companieInfo.getService().subscribe((footerResponse: any) => {
      return this.footerInfo = footerResponse;
    });
  }

  getInfoPage() {
    this._adService.getInfoPage(this.page).pipe(first()).subscribe(
      (response: any) => {
        response.forEach((value) => {
          this.infoPage[value.codigo] = value;
        });
      }, (error: any) => {
        console.error(error.status);
        console.error(error.statusText);
      }
    );
  }

}
