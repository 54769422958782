import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';

import { SupplierRegistrationService } from '@app/common/services/supplier-registration.service';
import { AdverstismentService } from '../../common/services/adverstisment.service';
import { HeaderService } from '@app/common/services/header.service';
import { EnvService } from '@app/common/services/env.service';
import { SupplierModel } from '@app/common/types/SupplierModel';

declare var $: any;

@Component({
  selector: 'app-supplier-registration',
  templateUrl: './supplier-registration.component.html',
  styleUrls: ['./supplier-registration.component.scss']
})
export class SupplierRegistrationComponent implements OnInit {

  public userLogged: string;
  public economicActivities: any;
  public economicSubActivities: any;
  public taxRegimes: any;
  public identificationsTypes: any;
  public listCities = [];
  public captchaValue: any;

  public flagValidateFiles = false;
  public flagValidateForm = false;
  public messageValidateFiles: string;

  public fileCertificateCCV: File = null;
  public fileRUT: File = null;
  public fileCertificateBank: File = null;
  public fileFinancialStatements: File = null;
  public fileCommercialReference1: File = null;
  public fileCommercialReference2: File = null;
  public fileIdManager: File = null;

  public flagPanelHome = true;
  public flagPanelProfile = false;
  public flagPanelContact = false;

  public validateModel: any;

  public showEmail = true;

  b2sContent: any;

  public currentTab = 'home';
  public selectedCompany;

  public supplierModel: SupplierModel = {
    businessName: '',
    shortName: '',
    documentID: '',
    documentIdVerify: '',
    economicActivity: '',
    economicSubactivity: '',
    webSite: '',
    pyme: '',
    messageBusiness: '',
    contactName: '',
    contactLastname: '',
    contactIdentificationType: '',
    contactIdentification: '',
    contactCharge: '',
    contactArea: '',
    contactEmail: '',
    contactIndicativeTelephone: '',
    contactTelephone: '',
    contactAddress: '',
    birthdate: '',
    contactCity: '',
    taxRegime: '',
    requestType: '1',
    captchaValue: null,
    checkAcepto: null,
    checkAutorizo: null
  };
  supplierCity: any;

  page = 'PAG-47';
  cmsData: any;

  cityTextSearch: string;
  selectedCity = false;

  constructor(private _principalService: SupplierRegistrationService,
    private _adService: AdverstismentService,
    private _toastr: ToastrService,
    private _hedaerService: HeaderService,
    private envService: EnvService) {

  }

  ngOnInit() {
    this.selectedCompany = localStorage.getItem('selectedCompany');
    this.showEmail = this.envService.clientName !== 'duratex';
    $('[data-toggle="tooltip"]').tooltip();
    this.userLogged = JSON.parse(localStorage.getItem('authenticate'));
    this.initModelSupplier();
    this.initFieldsForm();
    this.initAdverstisments();
    this.getContentB2s();
  }

  initModelSupplier() {
    this.supplierModel = {
      businessName: '',
      shortName: '',
      documentID: '',
      documentIdVerify: '',
      economicActivity: '',
      economicSubactivity: '',
      webSite: '',
      pyme: '',
      messageBusiness: '',
      contactName: '',
      contactLastname: '',
      contactIdentificationType: '',
      contactIdentification: '',
      contactCharge: '',
      contactArea: '',
      contactEmail: '',
      contactIndicativeTelephone: '',
      contactTelephone: '',
      contactAddress: '',
      birthdate: '',
      contactCity: '',
      taxRegime: '',
      requestType: '1',
      captchaValue: null,
      checkAcepto: null,
      checkAutorizo: null
    };

    this.validateModel = {
      businessName: true,
      shortName: true,
      documentID: true,
      documentIdVerify: true,
      economicActivity: true,
      // economicSubactivity: true,
      webSite: true,
      pyme: true,
      contactName: true,
      contactLastname: true,
      contactIdentificationType: true,
      contactIdentification: true,
      contactEmail: true,
      contactIndicativeTelephone: true,
      contactTelephone: true,
      contactAddress: true,
      birthdate: this.showEmail,
      contactCity: true,
      taxRegime: true
    };

    this.fileCertificateCCV = null;
    this.fileRUT = null;
    this.fileCertificateBank = null;
    this.fileFinancialStatements = null;
    this.fileCommercialReference1 = null;
    this.fileCommercialReference2 = null;
    this.fileIdManager = null;

    this.supplierCity = null;
    this.cityTextSearch = '';
    this.selectedCity = false;
  }

  initFieldsForm() {
    this._principalService.getEconomicActivities()
      .subscribe((data: any) => {
        this.economicActivities = data;
      });

    this._principalService.getTaxRegimes()
      .subscribe((data: any) => {
        this.taxRegimes = data;
      });

    this._principalService.getIdentificationsTypes()
      .subscribe((data: any) => {
        this.identificationsTypes = data;
      });
  }

  showToast(type, message, title, options) {
    if (type === 'error') {
      this._toastr.error(message, title, options);
    } else if (type === 'warning') {
      this._toastr.warning(message, title, options);
    } else if (type === 'success') {
      this._toastr.success(message, title, options);
    } else if (type === 'info') {
      this._toastr.info(message, title, options);
    }
  }


  initAdverstisments() {
    this._adService.getAdverstisments(this.page).subscribe((data: any) => {
      this.cmsData = data;
    });
  }


  updatelistEconomicSubactivity() {
    this.supplierModel.economicSubactivity = '';

    if (this.supplierModel.economicActivity) {
      this._principalService.getEconomicSubActivities(this.supplierModel.economicActivity)
        .subscribe((data: any) => {
          this.economicSubActivities = data;
        });
    } else {
      this.economicSubActivities = null;
    }
  }


  validateContactIdentificationRegisterSupplier(idDocument, document) {
    this._principalService.validateContactIdentificationRegisterSupplier(idDocument, document)
      .subscribe((data: any) => {
        if (data.error) {
          this.showToast('error', data.message, data.title, {});
          this.supplierModel.contactIdentification = '';
          this.supplierModel.contactIdentificationType = '';
        }
      });
  }


  validateNitSupplierRegister(documentId, documentIdVerify) {
    if (documentId && documentIdVerify) {
      this._principalService.validateNitSupplierRegister(documentId, documentIdVerify)
        .subscribe((data: any) => {
          if (data.error) {
            this.showToast('error', data.message, data.title, {});
            this.supplierModel.documentID = '';
            this.supplierModel.documentIdVerify = '';
          }
        });
    }
  }

  validateEmailContactSupplier(emailContact) {
    if (emailContact) {
      this._principalService.validateEmailContactSupplier(emailContact)
        .subscribe((data: any) => {
          if (data.error) {
            this.showToast('error', data.message, data.title, {});
            this.supplierModel.contactEmail = '';
          }
        });
    }
  }

  // getCitiesBySearchText(textSearch) {
  //   this.selectedCity = false;
  //   this.supplierCity = {};
  //   this.supplierModel.contactCity = null;
  //   if (textSearch.length >= 3) {
  //     this._principalService.getCities(textSearch)
  //       .subscribe((data: any) => {
  //         this.listCities = data;
  //       });
  //   }
  // }

  // setCityByAutocompleteField(city) {
  //   this.selectedCity = true;
  //   this.cityTextSearch = city.name;
  //   this.supplierCity = city;
  //   this.supplierModel.contactCity = city;
  // }

  handleFileInput(files: FileList, fileDestiny) {
    const file = files.item(0);
    if (file) {
      if (file.type === 'application/pdf') {
        if (fileDestiny === 'fileCertificateCCV') {
          this.fileCertificateCCV = file;
        }
        if (fileDestiny === 'fileRUT') {
          this.fileRUT = file;
        }
        if (fileDestiny === 'fileFinancialStatements') {
          this.fileFinancialStatements = file;
        }
        if (fileDestiny === 'fileCertificateBank') {
          this.fileCertificateBank = file;
        }
        if (fileDestiny === 'fileCommercialReference1') {
          this.fileCommercialReference1 = file;
        }
        if (fileDestiny === 'fileCommercialReference2') {
          this.fileCommercialReference2 = file;
        }
        if (fileDestiny === 'fileIdManager') {
          this.fileIdManager = file;
        }
      } else {
        this.showToast('error',
          'El archivo “' + file.name + '” que subiste tiene un formato inválido. Recuerda que, el formato válido para los archivos que se cargan es PDF',
          'Formato de archivo incorrecto',
          {});
      }
    } else {
      if (fileDestiny === 'fileCertificateCCV') {
        this.fileCertificateCCV = null;
      }
      if (fileDestiny === 'fileRUT') {
        this.fileRUT = null;
      }
      if (fileDestiny === 'fileFinancialStatements') {
        this.fileFinancialStatements = null;
      }
      if (fileDestiny === 'fileCertificateBank') {
        this.fileCertificateBank = null;
      }
      if (fileDestiny === 'fileCommercialReference1') {
        this.fileCommercialReference1 = null;
      }
      if (fileDestiny === 'fileCommercialReference2') {
        this.fileCommercialReference2 = null;
      }
      if (fileDestiny === 'fileIdManager') {
        this.fileIdManager = null;
      }
    }
  }

  onSubmit() {
    this.flagValidateFiles = false;
    if (this.currentTab === 'contact') {
      if (this.validateTabAnnexedDocuments()) {
        const files = [
          { key: 'CertificateCCV', file: this.fileCertificateCCV },
          { key: 'RUT', file: this.fileRUT },
          { key: 'FinancialStatements', file: this.fileFinancialStatements },
          { key: 'CertificateBank', file: this.fileCertificateBank },
          { key: 'CommercialReference1', file: this.fileCommercialReference1 },
          { key: 'CommercialReference2', file: this.fileCommercialReference2 },
          { key: 'IdManager', file: this.fileIdManager },
        ];

        this._principalService.postRegisterSupplier(this.supplierModel, files)
          .subscribe((data: any) => {
            if (data.error) {
              this.showToast('error', data.errorMessage, 'Solicitud Proveedores', {});
            } else {
              this.showToast('success', data.errorMessage, 'Solicitud exitosa', {});
              this.initModelSupplier();
              this.setPanel('home');
            }
          });
      }
    }
  }

  validateTabAnnexedDocuments() {
    let flag = true;
    let messageValidate = 'Para continuar con este proceso debes diligenciar los siguientes datos:';
    if (!this.fileCertificateCCV || !this.fileRUT || !this.fileCertificateBank || !this.fileIdManager) {
      messageValidate += '<br>Archivos:';
      if (!this.fileCertificateCCV) {
        messageValidate += ' <br>Certificado de Camara de Comercio, ';
      }
      if (!this.fileRUT) {
        messageValidate += ' <br>Registro Único Tributario RUT, ';
      }
      if (!this.fileCertificateBank) {
        messageValidate += ' <br>Certificación Bancaria, ';
      }
      if (!this.fileIdManager) {
        messageValidate += ' <br>Cédula de Representante Legal, ';
      }
      flag = false;
    }

    if (!this.supplierModel.checkAcepto) {
      messageValidate += ' <br>Aceptar términos y condiciones';
      flag = false;
    }
    if (!this.supplierModel.checkAutorizo) {
      messageValidate += ' <br>Autorizar tratamiendo de datos';
      flag = false;
    }

    if (!this.supplierModel.captchaValue) {
      messageValidate += ' <br>Resolver el CAPTCHA';
      flag = false;
    }

    if (!flag) {
      swal.fire({
        title: '<p>Faltan algunos datos por diligenciar</p>',
        html: messageValidate,
      });
    }

    return flag;
  }

  setPanel(panel) {
    if (panel === 'home') {
      this.flagPanelHome = true;
      this.currentTab = 'home';
      this.flagPanelProfile = false;
      this.flagPanelContact = false;
    } else if (panel === 'profile' && this.validateCurrentTab()) {
      this.flagPanelHome = false;
      this.flagPanelProfile = true;
      this.currentTab = 'profile';
      this.flagPanelContact = false;
    } else if (panel === 'contact' && this.validateCurrentTab() && this.currentTab === 'profile') {
      this.flagPanelHome = false;
      this.flagPanelProfile = false;
      this.flagPanelContact = true;
      this.currentTab = 'contact';
    }
  }

  setNextPanel(panel) {
    if (panel === 'home' && this.validateCurrentTab()) {
      this.flagPanelHome = false;
      this.flagPanelProfile = true;
      this.currentTab = 'profile';
      this.flagPanelContact = false;
    } else if (panel === 'profile' && this.validateCurrentTab()) {
      this.flagPanelHome = false;
      this.flagPanelProfile = false;
      this.flagPanelContact = true;
      this.currentTab = 'contact';
    }
  }

  setPreviousPanel(panel) {
    if (panel === 'profile') {
      this.flagPanelHome = true;
      this.flagPanelProfile = false;
      this.flagPanelContact = false;
      this.currentTab = 'home';
    } else if (panel === 'contact') {
      this.flagPanelHome = false;
      this.flagPanelProfile = true;
      this.currentTab = 'profile';
      this.flagPanelContact = false;
    }
  }

  validateCurrentTab() {
    let flag = true;
    if (this.currentTab === 'home') {
      let messageValidate = 'Para continuar con este proceso debes diligenciar los siguientes datos:';
      if (this.supplierModel.businessName.length <= 0 ||
        this.supplierModel.shortName.length <= 0 ||
        (this.supplierModel.documentID.length <= 0) ||
        this.supplierModel.documentIdVerify.length <= 0 ||
        this.supplierModel.economicActivity.length <= 0 ||
        /*this.supplierModel.economicSubactivity.length <= 0 ||*/
        // this.supplierModel.webSite.length <= 0 ||
        this.supplierModel.taxRegime.length <= 0 ||
        this.supplierModel.pyme.length <= 0
      ) {

        if (this.supplierModel.businessName.length <= 0) {
          messageValidate += '<br>Razón Social';
          this.validateModel.businessName = false;
        }
        if (this.supplierModel.shortName.length <= 0) {
          messageValidate += '<br>Nombre Abreviado';
          this.validateModel.shortName = false;
        }
        if (this.supplierModel.documentID.length <= 0) {
          messageValidate += '<br>NIT o ID Tributario';
          this.validateModel.documentID = false;
        }
        if (this.supplierModel.documentIdVerify.length <= 0) {
          messageValidate += '<br>Digito Verificación';
          this.validateModel.documentIdVerify = false;
        }
        if (this.supplierModel.taxRegime.length <= 0) {
          messageValidate += '<br>Regimen Tributario';
          this.validateModel.taxRegime = false;
        }
        if (this.supplierModel.economicActivity.length <= 0) {
          messageValidate += '<br>Actividad Económica';
          this.validateModel.economicActivity = false;
        }
        /*if (this.supplierModel.economicSubactivity.length <= 0) {
          messageValidate += '<br>Sub Actividad Económica';
          this.validateModel.economicSubactivity = false;
        }*/
        // if (this.supplierModel.webSite.length <= 0) {
        //   messageValidate += '<br>Sitio Web';
        //   this.validateModel.webSite = false;
        // }
        if (this.supplierModel.pyme.length <= 0) {
          messageValidate += '<br>MiPyme';
          this.validateModel.pyme = false;
        }

        flag = false;
        swal.fire({
          html: messageValidate,
          title: '<p>Faltan algunos datos por diligenciar</p>'
        });
      }
    }

    if (this.currentTab === 'profile') {
      let messageValidate = 'Para continuar con este proceso debes diligenciar los siguientes datos:';
      if (this.supplierModel.contactName.length <= 0 ||
        this.supplierModel.contactLastname.length <= 0 ||
        (this.supplierModel.contactIdentificationType.length <= 0) ||
        this.supplierModel.contactIdentification.length <= 0 ||
        this.supplierModel.contactEmail.length <= 0 ||
        this.supplierModel.contactIndicativeTelephone.length <= 0 ||
        this.supplierModel.contactTelephone.length <= 0 ||
        this.supplierModel.contactTelephone.length < 10 ||
        this.supplierModel.contactAddress.length <= 0 ||
        (this.supplierModel.birthdate.length <= 0 && this.showEmail) ||
        this.supplierModel.contactCity.length <= 0
      ) {

        if (this.supplierModel.contactName.length <= 0) {
          messageValidate += '<br>Nombre(s)';
          this.validateModel.contactName = false;
        }
        if (this.supplierModel.contactLastname.length <= 0) {
          messageValidate += '<br>Apellido(s)';
          this.validateModel.contactLastname = false;
        }
        if (this.supplierModel.contactIdentificationType.length <= 0) {
          messageValidate += '<br>Tipo Identificación';
          this.validateModel.contactIdentificationType = false;
        }
        if (this.supplierModel.contactIdentification.length <= 0) {
          messageValidate += '<br>Numero de Identificación';
          this.validateModel.contactIdentification = false;
        }
        if (this.supplierModel.contactIndicativeTelephone.length <= 0) {
          messageValidate += '<br>Indicativo';
          this.validateModel.contactIndicativeTelephone = false;
        }
        if (this.supplierModel.contactIndicativeTelephone.length > 4) {
          messageValidate += '<br>El Indicativo no puede ser mayor a 4 digitos';
          this.validateModel.contactIndicativeTelephone = false;
        }
        if (this.supplierModel.contactTelephone.length <= 0) {
          messageValidate += '<br>Teléfono Móvil';
          this.validateModel.contactTelephone = false;
        }
        if (this.supplierModel.contactTelephone.length < 10) {
          messageValidate += '<br>El Teléfono Móvil  debe tener 10 digitos';
          this.validateModel.contactTelephone = false;
        }
        if (this.supplierModel.contactTelephone.length > 9 && this.supplierModel.contactTelephone.length !== 10) {
          messageValidate += '<br>El Teléfono Móvil  debe tener 10 digitos';
          this.validateModel.contactTelephone = false;
        }
        if (this.supplierModel.contactEmail.length <= 0) {
          messageValidate += '<br>Email';
          this.validateModel.contactEmail = false;
        }
        if (this.supplierModel.contactAddress.length <= 0) {
          messageValidate += '<br>Dirección';
          this.validateModel.contactAddress = false;
        }
        if (this.supplierModel.birthdate.length <= 0 && this.showEmail) {
          messageValidate += '<br>Fecha de nacimiento';
          this.validateModel.birthdate = false;
        }

        if (this.supplierModel.contactCity.length <= 0) {
          messageValidate += '<br>Ciudad(s)';
          this.validateModel.contactCity = false;
        }


        flag = false;
        swal.fire({
          title: '<p>Faltan algunos datos por diligenciar</p>',
          html: messageValidate,
        });
      } else if (this.showEmail && !this.validateCurrentDate(this.supplierModel.birthdate)) {
        flag = false;
      } else {
        if (!this.validateFormatEmail(this.supplierModel.contactEmail)) {
          flag = false;
        }
      }
    }

    return flag;
  }


  selectLabelFileInput(text, file) {
    let label = text;
    if (file) {
      label = file.name;
    }
    return label;
  }

  getContentB2s() {
    this._hedaerService.getAGPParams(this.selectedCompany)
      .subscribe((contentReponse: any) => {
        return this.b2sContent = contentReponse;
      });
  }

  validateCurrentDate(date) {
    const currentDate = new Date();
    const birthdate = new Date(date);
    const birthdateString = birthdate.getFullYear() + '/' + (birthdate.getMonth() + 1) + '/' + (birthdate.getDate() + 1);
    const currentDateString = currentDate.getFullYear() + '/' + (currentDate.getMonth() + 1) + '/' + (currentDate.getDate());
    const currentDateFormat = new Date(currentDateString);
    const birthdateFormat = new Date(birthdateString);
    let validate = true;
    // tslint:disable-next-line:max-line-length
    const validateYerOld = new Date((currentDateFormat.getFullYear() - 18), (currentDateFormat.getMonth()), (currentDateFormat.getDate()));

    if (birthdateFormat >= currentDateFormat) {
      validate = false;
      swal.fire({
        title: '<p>Faltan algunos datos por diligenciar</p>',
        html: '<br>La fecha de nacimiento no puede ser mayor a la fecha actual',
      });
    } else {
      if (birthdateFormat > validateYerOld) {
        validate = false;
        swal.fire({
          title: '<p>Faltan algunos datos por diligenciar</p>',
          html: '<br>No se puede registrar un menor de edad.',
        });
      }
    }

    return validate;
  }

  validateFormatEmail(email) {
    let validate = true;
    // tslint:disable-next-line:max-line-length
    const regularExpression = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    validate = regularExpression.test(email);

    if (!validate) {
      swal.fire({
        title: '<p>Faltan algunos datos por diligenciar</p>',
        html: '<br>Debe ingresar un correo con un formato valido.',
      });
    }
    return validate;
  }
}
