import { Component, OnInit } from '@angular/core';
import { AdverstismentService } from '../../common/services/adverstisment.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  page = 'B2S-PAG-16';
  cmsData: any;
  public contentB2s = {
    contact: 0,
    provider: 0
  };
  constructor(public adService: AdverstismentService) {

  }

  ngOnInit() {
    this.adService.getAdverstisments(this.page).subscribe((data: any) => {

      this.cmsData = data;

    });
  }

  getContentB2s() {
    const provider = localStorage.getItem('provider');
    const contact = localStorage.getItem('contact');

    this.contentB2s.contact = parseFloat(contact);
    this.contentB2s.provider = parseFloat(provider);
  }
}
