import { Component, OnInit } from '@angular/core';
import { AdverstismentService } from '../../common/services/adverstisment.service';
import { SearchCompanyService } from '@src/app/common/services/search-company.service';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first, timeout } from 'rxjs/operators';



@Component({
  selector: 'app-search-company',
  templateUrl: './search-company.component.html',
  styleUrls: ['./search-company.component.scss']
})
export class SearchCompanyComponent implements OnInit {

  cmsData: any;
  page = 'PAG-47';
  b2sContent: any;
  cmsMessage: any;
  registerForm: FormGroup;
  public dataForm = {
    nitCompany: ''
  };
  public companyInfo;
  public messageError;
  public messageErrorResponse;
  public userLogged: string;
  public mostrarMensaje = false;
  public logo: string;

  // tslint:disable-next-line:variable-name
  constructor(private _adService: AdverstismentService,
    private _searchCompanyService: SearchCompanyService,
    private formBuilder: FormBuilder,
    private router: Router,
    private _toastr: ToastrService) { }

  ngOnInit() {
    this.userLogged = JSON.parse(localStorage.getItem('authenticate'));
    this.initAdverstisments();
    this.getContentB2s();
    this.getContentCms();
    const json = {
      data: 20,
    };
    this.getLoginService(json);
  }

  initAdverstisments() {
    this._adService.getAdverstisments(this.page).subscribe((data: any) => {
      this.cmsData = data;
    });
  }

  getContentB2s() {
    this._searchCompanyService.contentParamsB2s()
      .subscribe((contentReponse: any) => {
        return this.b2sContent = contentReponse;
      });
  }

  getContentCms() {
    this._searchCompanyService.contentCmsProvider()
      .subscribe((contentCmsResponse: any) => {
        return this.cmsMessage = contentCmsResponse;
      });
  }

  onSubmit(form: NgForm) {
    this.mostrarMensaje = false;
    const dataCompany = form.value.company;
    let message = '';
    this.companyInfo = '';
    this.messageError = '';
    this.messageErrorResponse = '';

    this.registerForm = this.formBuilder.group({
      company: [dataCompany, [Validators.required]]
    });

    if (this.registerForm.controls.company.errors) {
      if (this.registerForm.controls.company.errors.required) {
        message = message + '<br>Ingrese el Nit de la compañia.';
        return;
      }
    }

    this._searchCompanyService.postSendDataCompany(dataCompany)
      .subscribe(authResponse => {
        if (authResponse.existeTercero) {
          if (authResponse.terceroData.data) {
            this.companyInfo = authResponse.terceroData.data;
            this.mostrarMensaje = !this.companyInfo.length;
          }
          if (!authResponse.terceroData.data) {
            this.messageErrorResponse = 'No se encontró ningún registro';
          }
        }
        if (!authResponse.existeTercero) {
          // this.messageErrorResponse = authResponse.message;
          this._searchCompanyService.contenCmsError()
            .subscribe((errorResponse: any) => {
              this.router.navigate(['/supplier-registration']);
              this.showToast('error', errorResponse.contenido, 'El tercero consultado no existe', {});
            });
        }
      });
  }

  getLoginService(params) {
    this._searchCompanyService.getDataLogin(params).pipe(first(), timeout(1000)).subscribe(
      (response: any) => {
        if (response.error) {
          this.logo = localStorage.getItem('logo');
        } else {
          this.logo = response.logo;
        }
      }, (error: any) => {
        this.logo = localStorage.getItem('logo');
      }
    );
  }

  requestSuplier(nit: string) {
    if (nit.length < 1) {
      return;
    }
    this.router.navigate(['/supplier-registration/company/', nit]);
  }

  showToast(type, message, title, options) {
    if (type === 'error') {
      this._toastr.error(message, title, options);
    } else if (type === 'warning') {
      this._toastr.warning(message, title, options);
    } else if (type === 'success') {
      this._toastr.success(message, title, options);
    } else if (type === 'info') {
      this._toastr.info(message, title, options);
    }
  }
}
